import React, { useState, useEffect, useMemo, lazy, useRef, Suspense } from 'react';
import { k, useCss } from 'kremling';
import { DateTime } from 'luxon';
import { CpModal, CpButton, CpLoader, constants, CpWell } from 'canopy-styleguide!sofe';
import { infoToast, warningToast } from 'toast-service!sofe';
import { useHasAccess } from 'cp-client-auth!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { getClient } from 'src/resources/clients.resources';
import { retryInvoiceSync } from '../../resources/integrations.resources';

const IntegrationSyncErrorModal = lazy(() =>
  SystemJS.import('integrations-ui!sofe').then(module => module.getIntegrationSyncErrorModal())
);

export default function QboCell({ invoice, integrationInfo = {} }) {
  const scope = useCss(css);
  const retrySubscription = useRef();
  const { id } = invoice;
  const { third_party_url: url = '', third_party_number: invoiceNumber = '', third_party_error = null } = invoice;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [retryingSync, setRetryingSync] = useState(false);
  const [showIntegrationError, setShowIntegrationError] = useState(false);

  const userCanSync = useHasAccess('integrations_configure');

  const invoiceCreatedAfterQboSync = useMemo(() => {
    const invoicePostedAt = DateTime.fromISO(invoice.third_party_postable_at);
    const qboSyncedAt = DateTime.fromISO(integrationInfo.invoices.sync_status.first_synced_at);

    return invoicePostedAt > qboSyncedAt;
  }, [invoice, integrationInfo]);

  useEffect(() => {
    return () => retrySubscription.current?.unsubscribe();
  }, []);

  const retrySync = () => {
    setRetryingSync(true);
    retrySubscription.current = retryInvoiceSync(integrationInfo.id, id, integrationInfo.type).subscribe(
      () => {
        infoToast('Re-syncing invoice with QBO.');
        setRetryingSync(false);
      },
      e => {
        setRetryingSync(false);
        handleError(e);
      }
    );
  };

  const manualSync = () => {
    const clientId = invoice.relationships?.for.id;
    getClient(clientId, ['integrations']).subscribe(client => {
      const isConnected = !!client?.third_party_id;
      if (!isConnected) {
        warningToast(`Unable to sync because this invoice's client is not connected to QBO.`);
      } else {
        retrySync();
      }
    }, handleError);
  };

  if ((url || invoiceNumber) && integrationInfo.disconnect_error) {
    return (
      <div>
        <div onClick={() => setShowIntegrationError(true)} className="cp-flex-center">
          <div className="cp-color-app-error-text cp-mr-4">{invoiceNumber ? invoiceNumber : 'Error'}</div>
          <CpButton icon="alert-triangle-open-large" aria-label="Warning" fill="var(--cp-color-app-secondary-text)" />
        </div>
        <Suspense fallback={<></>}>
          <IntegrationSyncErrorModal
            show={showIntegrationError}
            close={() => setShowIntegrationError(false)}
            integrationInfo={integrationInfo}
            checkIntegrationAuth={integrationInfo.checkIntegrationAuth}
          />
        </Suspense>
      </div>
    );
  } else if (third_party_error) {
    return (
      <div {...scope}>
        <div className="cp-flex-center">
          {retryingSync ? (
            <CpLoader />
          ) : (
            <>
              <div className="error-status" onClick={() => setShowErrorModal(true)}>
                {invoiceNumber ? invoiceNumber : 'Error'}
              </div>
              <CpButton
                icon="af-refresh"
                aria-label="Retry syncing invoice"
                fill="var(--cp-color-app-secondary-text)"
                onClick={retrySync}
              />
            </>
          )}
        </div>
        <CpModal show={showErrorModal} onClose={() => setShowErrorModal(false)}>
          <CpModal.Header title="Invoice Sync Error" />
          <CpModal.Body>
            <div {...scope}>
              <div>Your request to sync this invoice has returned an error from QuickBooks Online:</div>
              <CpWell level={2} className="error-box">
                <div className="cp-flex-center">
                  <img
                    src="https://cdn.canopytax.com/images/qbo_logo_small_circle.svg"
                    height="24px"
                    alt="QuickBooks Online Logo"
                  />
                  <strong className="cp-ml-16">Error</strong>
                </div>
                <div className="cp-mt-8">
                  {third_party_error.exception_message ||
                    `If you need assistance, please contact support by calling ${constants.canopyPhone} or reaching out to the
                support chat line.`}
                </div>
              </CpWell>
            </div>
          </CpModal.Body>
          {url && (
            <CpModal.Footer>
              {url && (
                <CpButton btnType="primary" anchor href={url} target="_blank">
                  View invoice
                </CpButton>
              )}
              <CpButton
                btnType="flat"
                anchor
                href="https://support.getcanopy.com/hc/en-us/articles/9126446210587-QBO-Integration-Error-Codes"
                target="_blank">
                Learn more
              </CpButton>
            </CpModal.Footer>
          )}
        </CpModal>
      </div>
    );
  } else if (invoiceNumber) {
    return url ? (
      <a href={url} target="_blank" rel="noreferrer">
        {invoiceNumber}
      </a>
    ) : (
      <div>{invoiceNumber}</div>
    );
  } else if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        Access
      </a>
    );
  } else if (userCanSync && invoiceCreatedAfterQboSync) {
    return retryingSync ? <CpLoader /> : <a onClick={manualSync}>Sync Now</a>;
  } else {
    return <div>—</div>;
  }
}

const css = k`
  .error-status {
    margin-right: 0.4rem;
    color: var(--cp-color-app-error-text);
  }

  .error-status:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .error-box {
    padding: 1.2rem;
    margin-top: 1.6rem;
    border-radius: 0.8rem;
  }
`;
